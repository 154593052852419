<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('editpass.title')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div class="maincontent">
      <div class="spacing">
        <van-tabs v-model="active" color="#004ea3">
          <!-- <van-tab :title="$t('editpass.tphone')">
            <van-row style="margin-top:20px">
              <van-cell-group>
                <van-field
                  v-model="form.username"
                  clearable
                  :placeholder="$t('editpass.notice1')"
                >
                  <template #left-icon>
                    <van-icon
                      class="iconfont"
                      class-prefix="icon"
                      name="shouji"
                    />
                  </template>
                  <template #label>
                    <van-dropdown-menu active-color="#1989fa">
                      <van-dropdown-item v-model="quhao" :options="option" />
                    </van-dropdown-menu>
                  </template>
                </van-field>
                <van-field
                  v-model="form.code"
                  clearable
                  center
                  :placeholder="$t('editpass.notice2')"
                  class="smsbtn"
                >
                  <template #left-icon>
                    <van-icon class="iconfont" class-prefix="icon" name="mima" />
                  </template>
                  <template #button>
                    <van-button
                      v-if="phonebtn"
                      size="small"
                      type="primary"
                      @click="sendsms"
                      >{{ $t('editpass.sendcode') }}</van-button
                    >
                    <van-button v-else
                      >{{ phonetime }}s{{ $t('editpass.again') }}</van-button
                    >
                  </template>
                </van-field>
                <van-field
                  v-model="form.password"
                  :type="type"
                  :right-icon="eye"
                  :placeholder="$t('editpass.notice3')"
                  @click-right-icon="openeye"
                >
                  <template #left-icon>
                    <van-icon class="iconfont" class-prefix="icon" name="mima" />
                  </template>
                </van-field>
                <van-field
                  v-model="form.password2"
                  :type="type"
                  :right-icon="eye"
                  :placeholder="$t('editpass.notice4')"
                  @click-right-icon="openeye"
                >
                  <template #left-icon>
                    <van-icon class="iconfont" class-prefix="icon" name="mima" />
                  </template>
                </van-field>
              </van-cell-group>
            </van-row>
            <van-button class="mybtn" @click="submit">{{
              $t('editpass.editbtn')
            }}</van-button>
          </van-tab> -->
          <van-tab :title="$t('editpass.temail')">
            <van-row style="margin-top:10px">
              <van-cell-group>
                <van-field
                  v-model="form.username"
                  clearable
                  :placeholder="$t('editpass.notice5')"
                >
                  <template #left-icon>
                    <van-icon
                      class="iconfont"
                      class-prefix="icon"
                      name="youxiang"
                    />
                  </template>
                </van-field>
                <van-field
                  v-model="form.code"
                  clearable
                  center
                  :placeholder="$t('editpass.notice2')"
                  class="smsbtn"
                >
                  <template #left-icon>
                    <van-icon class="iconfont" class-prefix="icon" name="mima" />
                  </template>
                  <template #button>
                    <van-button
                      v-if="emailbtn"
                      size="small"
                      type="primary"
                      color="#004ea3"
                      @click="sendemail"
                    >{{ $t('editpass.sendcode') }}</van-button>
                    <van-button
                      v-else
                      size="small"
                      type="primary"
                      color="#004ea3"
                      disabled>{{ emailtime }}s{{ $t('editpass.again') }}</van-button>
                  </template>
                </van-field>
                <van-field
                  v-model="form.password"
                  :type="type"
                  :right-icon="eye"
                  :placeholder="$t('editpass.notice3')"
                  @click-right-icon="openeye"
                >
                  <template #left-icon>
                    <van-icon class="iconfont" class-prefix="icon" name="mima" />
                  </template>
                </van-field>
                <van-field
                  v-model="form.password2"
                  :type="type"
                  :right-icon="eye"
                  :placeholder="$t('editpass.notice4')"
                  @click-right-icon="openeye"
                >
                  <template #left-icon>
                    <van-icon class="iconfont" class-prefix="icon" name="mima" />
                  </template>
                </van-field>
              </van-cell-group>
            </van-row>
            <van-button type="default" color="#004ea3" block class="s-submit-btn" @click="submit">{{ $t('editpass.editbtn') }}</van-button>
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: 0,
      quhao: '86',
      option: [],
      form: {
        username: '',
        password: '',
        password2: '',
        code: ''
      },
      type: 'password',
      eye: 'closed-eye',
      phonebtn: true,
      phonetime: 60,
      emailbtn: true,
      emailtime: 60
    }
  },
  created() {
    this.getcodelist()
  },
  methods: {
    // 获取区号
    async getcodelist() {
      const { data } = await this.$http.get('/home/home/getcodelist')
      if (data) {
        if (data.code === 200) {
          data.data.forEach(item => {
            this.option.push({ text: '+' + item.code, value: item.code })
          })
        }
      }
    },
    // 查看密码
    openeye(event) {
      if (this.type === 'password') {
        this.eye = 'eye'
        this.type = 'text'
      } else {
        this.eye = 'closed-eye'
        this.type = 'password'
      }
    },
    // 发送手机验证码
    async sendsms() {
      if (!this.form.username) {
        this.$toast.fail(this.$t('editpass.notice1'))
        return false
      }
      const { data } = await this.$http.post('/home/home/sendsms', {
        phone: this.form.username,
        area: this.quhao
      })
      if (data) {
        if (data.code === 200) {
          this.phonebtn = false
          this.phonetime = 60
          this.$toast.success(this.$t('editpass.codesent'))
          const phoneTimer = setInterval(() => {
            this.phonetime--
            if (this.phonetime <= 0) {
              clearInterval(phoneTimer)
              this.phonebtn = true
            }
          }, 1000)
        } else {
          this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')))
        }
      }
    },
    // 发送邮箱验证码
    async sendemail() {
      if (!this.form.username) {
        this.$toast.fail(this.$t('editpass.notice5'))
        return false
      }
      const { data } = await this.$http.post('/home/home/sendemail', {
        email: this.form.username
      })
      if (data) {
        if (data.code === 200) {
          this.emailbtn = false
          this.emailtime = 60
          this.$toast.success(this.$t('editpass.codesent'))
          const emailTimer = setInterval(() => {
            this.emailtime--
            if (this.emailtime <= 0) {
              clearInterval(emailTimer)
              this.emailbtn = true
            }
          }, 1000)
        } else {
          this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')))
        }
      }
    },
    // 修改密码
    async submit() {
      if (!this.form.code || !this.form.username || !this.form.password) {
        this.$toast.fail(this.$t('editpass.complate'))
        return false
      }
      if (this.form.password !== this.form.password2) {
        this.$toast.fail(this.$t('editpass.eqpass'))
        return false
      }
      const { data } = await this.$http.post('/home/home/changepass', this.form)
      if (data) {
        if (data.code === 200) {
          this.$toast.success(
            this.getlang(data.msg, localStorage.getItem('lang'))
          )
        } else {
          this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')))
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.maincontent {
  padding-top: 50px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #f7f7f7;
  height: 100vh;
  .spacing{
    padding: 1.25rem;
    .van-cell-group{
      background: #f7f7f7;
      .van-cell:first-child {
        ::v-deep {
          .van-cell__value{
            &:before {
              display: none;
            }
          }
        }
      }
      ::v-deep {
        .van-cell__value{
          &:before {
            display: inline-block;
            content: '';
            width: 0.0625rem;
            height: 1.375rem;
            background: #E5E5E5;
            position: absolute;
            left: 0;
            top: 0.2rem;
          }
        }
        .van-field__control {
          font-weight: bold;
          font-size: 0.75rem;
          color: #004EA3;
          padding-left: 0.6rem;
        }
      }
    }
  }
  .van-dropdown-menu /deep/.van-dropdown-menu__bar {
    box-shadow: none;
    height: 1.5rem;
    background: transparent !important;
  }
  /deep/.van-tab {
    font-size: 1rem !important;
  }
  /deep/.van-tab--active {
    color: #004ea3;
    font-weight: 500;
  }
  .van-button {
    //font-size: 0.8rem;
    //background-color: rgb(0, 78, 163);
    //color: white;
    //padding: 5px;
    //border-radius: 6px;
    //border: none;
  }
  .van-field {
    padding: 0.5625rem 0.625rem;
    font-size: 1rem;
    margin-bottom: 0.625rem;
    border-radius: 0.3125rem;
    input {
      color: #000;
      padding-bottom: 0.53333rem;
      padding-left: 0.4rem;
      border-bottom: 0.02667rem solid #004ea3;
    }
  }
  /deep/.van-field__label {
    width: auto;
  }
  /deep/ .van-icon,
  /deep/.van-field__right-icon {
    color: #004ea3;
    font-size: 1.2rem;
  }
  .van-tab {
    font-size: 1rem;
  }
  .van-tab--active {
    color: #004ea3;
  }

  .van-icon-clear {
    color: #b3b3b3;
    font-size: 1rem;
  }
  .van-field__control {
    padding-left: 5px;
  }
  .s-submit-btn {
    margin-top: 6.25rem;
  }
}
.iconfont {
  color: #004ea3;
  font-size: 18px;
}
</style>
